@charset 'utf-8';
@import 'color';
@import 'mixin';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'components/burger';
@import 'components/slick';
@import 'components/slick-theme';
@import 'components/aos';
@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;
@import url('https://fonts.googleapis.com/css?family=Arima+Madurai:300,400,700,900|Roboto:300,400,700');


.spip-admin-float{
    z-index: 9999999999;
    display: block;
    position: relative;
}
 


* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

.pagination_spip {
    margin-bottom: 20px;
    text-align: center;
    color: $primary;
    padding: 8px;
    .on {
        color: $secondary;
    }
}

.formulaire_crayon .crayon-active {
    font-size: 15px!important;
    color: $noir!important;
    min-height: 250px!important;
    p {
        max-width: 100%!important;
    }
}

body {
    color: $noir;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    @include font-size(16px);
}

main {
    display: block;
    overflow: hidden;
}

.h1_hidden {
    font-size: 0px !important;
    text-indent: -99999999px !important;
}

.slick-prev:before,
.slick-next:before {
    color: $primary;
    font-size: 35px;
}

.slick-next {
    right: 30px;
    z-index: 99; // top: 30%;
}

.slick-prev {
    left: 10px;
    z-index: 99; // top: 30%;
}

.slick-slide {
    outline: none;
}

.bold {
    font-weight: 700;
}

.row_100 {
    max-width: 80%!important;
}

.row_200 {
    max-width: 100%!important;
}

.width_100 {
    width: 100%!important;
}

.aligne_center {
    text-align: center;
}

.margin_section {
    margin-top: 70px!important;
    margin-bottom: 70px!important;
}

.padding_section {
    padding-top: 70px!important;
    padding-bottom: 70px!important;
}

.clear {
    clear: both;
}

.parent {
    position: relative;
    width: 50%;
    height: 200px;
    margin: 20px auto;
}

.bg_primary {
    background: $primary;
}

.relative {}

.child {
    padding: 20px;
    &.both {
        @include center;
    }
    &.horizontal {
        @include center(true,
        false);
    }
    &.vertical {
        @include center(false,
        true);
    }
}

h1,
h2,
h3,
h4 h5,
h6 {}

h1 {
    @include font-size(40px);
    color: $noir;
    font-weight: 900;
    text-transform: uppercase;
    font-family: "Arima Madurai";
    @include breakpoint(small down) {
        @include font-size(30px);
    }
}

h2 {
    @include font-size(35px);
    font-weight: 900;
    color: $primary;
    font-family: "Arima Madurai";
    @include breakpoint(small down) {
        @include font-size(30px);
    }
}

h3 {
    @include font-size(22px); // font-family: '';
    // color: ;
    font-family: "Arima Madurai";
    font-weight: 900;
}

h4 {
    // @include font-size();
    // font-family: '';
    color: $primary;
    font-weight: 900;
    font-family: "Arima Madurai";
}

h5 {
    // @include font-size();
    // font-family: '';
}

li {
    list-style: none!important;
}

.footer_crea {
    clear: both;
    a {
        @include font-size(13px);
        text-align: center;
        width: 100%;
        display: block;
        padding: 10px;
        color: $noir;
        @include transition (.3s);
        &:hover {
            color: $rose;
            .heartbeat {
                &:after,
                &:before {
                    position: absolute;
                    content: "";
                    background: $rose;
                }
            }
        }
    }
}

#404 {
    header {
        min-height: 100%;
    }
}

// BUTTON =============================================================================================
.button {
    background: $primary;
    &:hover {
        background: $secondary;
    }
}


.cta,
focus,
input[type='submit'] {
    cursor: pointer;
    padding: 10px 30px;
    color: $snow;
    text-transform: uppercase;
    background: $primary;
    border: solid 2px $primary;
    font-weight: 600;
    display: inline-block;
    @include transition(.35s);
    @include border-radius(30px 30px 30px 30px);
    &:hover {
        background: $snow;
        color: $primary;
        border: solid 2px $primary;
    }
}
.box_actu{
    &:hover{
        .cta{
            background: $snow;
        color: $primary;
        border: solid 2px $primary;
        }
    }
}

.lien_pagination{
    color: $primary!important;
}

// BUTTON =============================================================================================
// HEADER --------------------------------------------------------------------------------------------
.sticky {
    @include transition(background .35s);
    max-width: 100%!important;
    margin-right: 0!important;
    &.is-stuck {
        // background: $bg;
        background: rgba($snow, .95);
        padding-right: 30px!important;
    }
}

header {
    margin-bottom: 5px;
    background-position: center;
    background-size: cover;
    .nav-principale {
        position: relative;
        z-index: 99;
        display: block;
        .logo {
            max-width: 164px!important;
            float: left;
            margin-top: 10px;
        }
        .menu {
            // text-align: right;
            // display: block;
            width: auto;
            float: right;

            ul {
                // float: right; 
                display: inline-block;
                text-align: right;
                li {
                    // width: calc(100% / 5);
                    display: inline-block;
                    position: relative; // float: left;
                    a {
                        font-family: "Arima Madurai";
                        padding-top: 35px!important;
                        padding-bottom: 20px;
                        width: 100%;
                        text-align: center;
                        display: block;
                        color: $noir;
                        margin-right: 5px;
                        margin-left: 5px;
                        padding: 0;
                        @include transition (.3s);
                        font-weight: 500;
                        &:hover {
                            color: $primary;
                        }
                        &.on {
                            font-weight: 900;
                            color: $primary;
                            padding-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
}




.social {
    position: relative;
    width: 100%;
    margin-right: 0!important;
    margin-top: 25px;
    background: url("../img/circle.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    ul {
        display: block;
        width: auto !important;
        margin-right: 0!important;
        margin-left: 0;
    }
    li {
        display: inline-block;
        @include transition(.5s);
        a {
            @include transition(.5s);
            img {
                width: 25px;
                display: inline-block;
                @include transition(.35s);
                margin-right: 5px;
            }
            &:hover {
                img {
                    opacity: .5;
                }
            }
        }
        &:nth-child(1) {

            &.scroll {

                transform: translateY(20px);
                @include breakpoint(small down) {
                    transform: translateY(0px);
                }
            }
        }
        &:nth-child(2) {

            &.scroll {

                transform: translateY(40px);
                @include breakpoint(small down) {
                    transform: translateY(0px);
                }
            }
        }
        &:nth-child(3) {

            &.scroll {

                transform: translateY(60px);
                @include breakpoint(small down) {
                    transform: translateY(0px);
                }
            }
        }
        &:nth-child(4) {

            &.scroll {

                transform: translateY(80px);
                @include breakpoint(small down) {
                    transform: translateY(0px);
                }
            }
        }
        &:nth-child(5) {

            &.scroll {

                transform: translateY(100px);
                @include breakpoint(small down) {
                    transform: translateY(0px);
                }
            }
        }
    }
}


.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// HEADER --------------------------------------------------------------------------------------------
// INTRO --------------------------------------------------------------------------------------------
.bg_orange {
    background: $secondary;
}
.text_split {
    column-count: 2;
    -moz-column-count: 2;
    -webkit-column-count: 2;
}
// INTRO END --------------------------------------------------------------------------------------------
// ACTU  --------------------------------------------------------------------------------------------
.bg_gris {
    background: $gris;
}

.box_actu {
    background: $snow;
    display: block;
    color: $noir;
    @include transition(.35s);
    margin-bottom: 60px;
    &:hover {
        color: $primary;
    }
    .date{
        font-weight: 900;
        @include font-size(13px);
    }
    .img_actu {
        @include aspect-ratio(19,
        12);
        min-width: 100%;
    }
    .padding_box {
        padding: 15px 30px;
    }
    .cta{
        margin: 0 auto;
        display: block;
    }
}

// ACTU END --------------------------------------------------------------------------------------------
// ARTICLE  --------------------------------------------------------------------------------------------


.box_article {
    background: $snow;
    display: block;
    color: $noir;
    @include transition(.35s);
    margin-bottom: 30px;
    .date{
        font-weight: 900;
        @include font-size(35px);
        color: $primary;
        margin-top: -40px;
        font-family: "Arima Madurai";
    }
    .img_actu {
        @include aspect-ratio(19,
        12);
        min-width: 100%;
    }
    .padding_box {
        padding: 15px 30px;
    }
    .cta{
        margin: 0 auto;
        display: block;
    }
}

// ARTICLE END --------------------------------------------------------------------------------------------
// QUISOUSJE  --------------------------------------------------------------------------------------------
.quisuisje{
    background: url("../img/logo-bg.png");
    background-size: 50%;
    background-position: right;
    background-repeat: no-repeat;
}
// QUISOUSJE END --------------------------------------------------------------------------------------------
//TEST BLOC SLICK A SUPPR ==============================================================================
// .first_slick {
//     width: 100%;
//     height: 100%;
//     .block_slick {
//         color: $snow;
//         min-height: 200px;
//         // width: 100px;
//         margin: 0;
//         height: auto;
//         img{
//             margin: 0 auto;
//         }
//     }
// }

.slick-slide{
    opacity: .5;
}
.slick-current{
    opacity: 1;
}

.first_slick {
    padding: 0;
}


.img_article {
    position: inherit;
    height: 400px;
    width: auto!important; // @include aspect-ratio(4, 3);
    margin-bottom: 0;
}



//END TEST BLOC SLICK A SUPPR ==============================================================================
// AGENDA ==============================================================================
.block_agenda{
    iframe{
        min-width: 100%;
        max-width: 100%;
    }
}
// AGENDA END ==============================================================================
// footer ===============================================================================================
// footer ===============================================================================================
footer{
    background:#f2f2f2;
}

.umpb_footer {
    text-align: center;
    padding-bottom: 20px;
    @include font-size(13px);
    margin-bottom: 0;
    background: $noir;
    color: $snow;
}

.coordonnees {
    a {
        color: $primary;
        font-weight: 600
    }
    li {
        margin-bottom: 4px;
    }
}

textarea,
input,
input[type='text'],
input[type='email'],
input[type='tel'] {
    background: #eaeaea;
    border: none;
    box-shadow: none;
}

.map {
    position: relative;
    #googleMap1 {
        position: relative!important;
        width: 100%;
        height: 500px !important;
        display: block;
    }
}

// footer ===============================================================================================
// footer ===============================================================================================
@include breakpoint(medium down) {
.text_split {
    column-count: 1!important;
    -moz-column-count: 1!important;
    -webkit-column-count: 1!important;
}
}
@include breakpoint(small down) {
    .pmenusmall{
        padding-top: 100px;
    }
    .menu_absolute{
        position: absolute;
        width: 100%;
    }
    .navigation--button {
        display: block;
    }
    .menu {
        @include transition(height .5s);
        overflow: hidden;
        height: 0px;
    }
    .menu_visible {
        height: 375px !important;
        @include transition(height .3s);
    }
    .nav-principale {
        img {
            display: block;
            margin: 0 auto;
            width: 50%;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        ul {
            margin: 0;
            width: 100%;
            .menu {
                margin: 0;
                padding: 0;
                width: 100%;
                background:$snow;
                ul {
                    li {
                        display: block;
                        width: 100%;
                        text-align: center;
                        float: none!important;
                        a {
                            margin: 0;
                            padding-top: 25px;
                            padding-bottom: 25px;
                            @include transition (.3s);
                            &:hover {
                                background: $primary;
                                color: $snow;
                            }
                        }
                        .active {
                            background: $primary;
                        }
                    }
                }
            }
        }
    }
}